<template>
  <div class="app-container">
    <div>
      <div>
        <el-select
          v-if="!this.$store.getters.restaurantId"
          v-model="restaurantID"
          class="search-select"
          type="select"
          :clearable="true"
          style="margin-bottom: 10px"
          :placeholder="$t('common.restaurant')"
          @input="handleFilterChanged($event)"
        >
          <el-option
            v-for="restaurant in restaurants"
            :key="restaurant.id"
            :label="restaurant.name"
            :value="restaurant.id"
          />
        </el-select>
        <el-date-picker
          v-model="fromDate"
          type="date"
          style="margin-left: 4px"
          :format="'dd-MM-yyyy'"
          :placeholder="$t('report.fromDate')"
          name="date"
          :picker-options="pickerOptions"
          @input="handleFilterChanged($event)"
        />
        <el-date-picker
          v-model="untilDate"
          type="date"
          style="margin-left: 4px"
          :format="'dd-MM-yyyy'"
          :placeholder="$t('report.untilDate')"
          name="date"
          :picker-options="pickerOptions"
          @input="handleFilterChanged($event)"
        />
        <editor-button
          :icon-only="false"
          :loading="loading"
          button-translation="route.exportExcel"
          action="primary"
          :disabled="!selectedRestaurantName"
          @click="downloadReportAsync"
        />
      </div>
    </div>
    <div ref="reportContainer" class="reportContainer">
      <h3 v-if="selectedRestaurantName" class="revenue-report-header">
        {{
          $t('revenueReport.header', {
            restaurant: selectedRestaurantName,
            fromDate: formatDate(fromDate),
            untilDate: formatDate(untilDate)
          })
        }}
      </h3>
      <el-row :gutter="20">
        <el-col :xs="24" :lg="8">
          <div class="grid-content report-block">
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.totalOrdersCount') }}</span>
              </el-col>
              <el-col :span="6">
                <span>{{ loadedData.revenueCounts.totalOrdersCount }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.ordersByPhoneCount') }} </span>
              </el-col>
              <el-col :span="6">
                <span>{{ loadedData.revenueCounts.ordersByPhoneCount }}</span>
              </el-col>
              <el-col :span="3">
                <span>{{ formatPercentage(loadedData.revenueCounts.orderByPhonePercentage) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.ordersWebsiteCount') }} </span>
              </el-col>
              <el-col :span="6">
                <span>{{ loadedData.revenueCounts.ordersWebsiteCount }}</span>
              </el-col>
              <el-col :span="3">
                <span>{{ formatPercentage(loadedData.revenueCounts.orderWebsitePercentage) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.ordersThuisbezorgdCount') }} </span>
              </el-col>
              <el-col :span="6">
                <span>{{ loadedData.revenueCounts.ordersThuisbezorgdCount }}</span>
              </el-col>
              <el-col :span="3">
                <span>{{ formatPercentage(loadedData.revenueCounts.ordersThuisbezorgdPercentage) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.ordersSneletenCount') }} </span>
              </el-col>
              <el-col :span="6">
                <span>{{ loadedData.revenueCounts.ordersSneletenCount }}</span>
              </el-col>
              <el-col :span="3">
                <span>{{ formatPercentage(loadedData.revenueCounts.ordersSneletenPercentage) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.ordersAppCount') }} </span>
              </el-col>
              <el-col :span="6">
                <span>{{ loadedData.revenueCounts.ordersAppCount }}</span>
              </el-col>
              <el-col :span="3">
                <span>{{ formatPercentage(loadedData.revenueCounts.ordersAppPercentage) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.ordersRestaurantCount') }} </span>
              </el-col>
              <el-col :span="6">
                <span>{{ loadedData.revenueCounts.ordersRestaurantCount }}</span>
              </el-col>
              <el-col :span="3">
                <span>{{ formatPercentage(loadedData.revenueCounts.ordersRestaurantPercentage) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.ordersPayterCount') }} </span>
              </el-col>
              <el-col :span="6">
                <span>{{ loadedData.revenueCounts.ordersPayterCount }}</span>
              </el-col>
              <el-col :span="3">
                <span>{{ formatPercentage(loadedData.revenueCounts.ordersPayterPercentage) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.ordersOthersCount') }} </span>
              </el-col>
              <el-col :span="6">
                <span>{{ loadedData.revenueCounts.ordersOthersCount }}</span>
              </el-col>
              <el-col :span="3">
                <span>{{ formatPercentage(loadedData.revenueCounts.ordersOthersPercentage) }}</span>
              </el-col>
            </el-row>
            <el-row />
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.bringYourOwnCount') }} </span>
              </el-col>
              <el-col :span="6">
                <span>{{ loadedData.revenueCounts.bringYourOwnCount }}</span>
              </el-col>
            </el-row>
          </div>
          <el-row class="bg-white" />
          <div class="grid-content report-block">
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.daysCount') }} </span>
              </el-col>
              <el-col :span="6">
                <span
                  >{{ loadedData.revenueCounts.daysWithOrderCount }} ({{ loadedData.revenueCounts.daysCount }})</span
                >
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.totalCustomersCount') }} </span>
              </el-col>
              <el-col :span="6">
                <span>{{ loadedData.revenueCounts.totalCustomersCount }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.totalUniqueCustomersCount') }} </span>
              </el-col>
              <el-col :span="6">
                <span>{{ loadedData.revenueCounts.totalUniqueCustomersCount }}</span>
              </el-col>
            </el-row>
          </div>
          <el-row class="bg-white" />
          <el-row class="bg-white" />
          <el-row class="bg-white" />
          <el-row class="bg-white" />
          <div class="grid-content report-block">
            <el-row>
              <el-col :span="19">
                <span> {{ $t('revenueReport.employeeHours') }} </span>
              </el-col>
              <el-col :span="5">
                <span>{{ formatHours(loadedData.employeeCosts.employeeHours) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="19">
                <span> {{ $t('revenueReport.employeeLaborCosts') }} </span>
              </el-col>
              <el-col :span="5">
                <span>{{ formatAmount(loadedData.employeeCosts.employeeLaborCosts) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="19">
                <span> {{ $t('revenueReport.employeeLaborCostsPercentage') }} </span>
              </el-col>
              <el-col :span="5">
                <span>{{ formatPercentage2(loadedData.employeeCosts.employeeLaborCostsPercentage) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="19">
                <span> {{ $t('revenueReport.employeeOtherCosts') }} </span>
              </el-col>
              <el-col :span="5">
                <span>{{ formatAmount(loadedData.employeeCosts.employeeOtherCosts) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="19">
                <span> {{ $t('revenueReport.employeeNumberOfDays') }} </span>
              </el-col>
              <el-col :span="5">
                <span>{{ loadedData.employeeCosts.employeeNumberOfDays }}</span>
              </el-col>
            </el-row>
          </div>
        </el-col>

        <el-col :xs="24" :lg="8">
          <div class="grid-content report-block">
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.revenueTakeAwayCash') }} </span>
              </el-col>
              <el-col :span="6">
                <span>{{ formatAmount(loadedData.revenuePerCategory.revenueTakeAwayCash) }}</span>
              </el-col>
              <el-col :span="3">
                <span>{{ formatPercentage(loadedData.revenuePerCategory.percentageRevenueTakeAwayCash) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.revenueTakeAwayPIN') }} </span>
              </el-col>
              <el-col :span="6">
                <span>{{ formatAmount(loadedData.revenuePerCategory.revenueTakeAwayPIN) }}</span>
              </el-col>
              <el-col :span="3">
                <span>{{ formatPercentage(loadedData.revenuePerCategory.percentageRevenueTakeAwayPIn) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.revenueDiningCash') }} </span>
              </el-col>
              <el-col :span="6">
                <span>{{ formatAmount(loadedData.revenuePerCategory.revenueDiningCash) }}</span>
              </el-col>
              <el-col :span="3">
                <span>{{ formatPercentage(loadedData.revenuePerCategory.percentageRevenueDiningCash) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.revenueDiningPIN') }} </span>
              </el-col>
              <el-col :span="6">
                <span>{{ formatAmount(loadedData.revenuePerCategory.revenueDiningPIN) }}</span>
              </el-col>
              <el-col :span="3">
                <span>{{ formatPercentage(loadedData.revenuePerCategory.percentageRevenueDiningPIn) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.revenuePhoneCash') }} </span>
              </el-col>
              <el-col :span="6">
                <span>{{ formatAmount(loadedData.revenuePerCategory.revenuePhoneCash) }}</span>
              </el-col>
              <el-col :span="3">
                <span>{{ formatPercentage(loadedData.revenuePerCategory.percentageRevenuePhoneCash) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.revenuePhonePIN') }} </span>
              </el-col>
              <el-col :span="6">
                <span>{{ formatAmount(loadedData.revenuePerCategory.revenuePhonePIN) }}</span>
              </el-col>
              <el-col :span="3">
                <span>{{ formatPercentage(loadedData.revenuePerCategory.percentageRevenuePhonePIN) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.revenuePhoneElectronic') }} </span>
              </el-col>
              <el-col :span="6">
                <span>{{ formatAmount(loadedData.revenuePerCategory.revenuePhoneElectronic) }}</span>
              </el-col>
              <el-col :span="3">
                <span>{{ formatPercentage(loadedData.revenuePerCategory.percentageRevenuePhoneElectronic) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.revenueOnCredit') }} </span>
              </el-col>
              <el-col :span="6">
                <span>{{ formatAmount(loadedData.revenuePerCategory.revenueOnCredit) }}</span>
              </el-col>
              <el-col :span="3">
                <span>{{ formatPercentage(loadedData.revenuePerCategory.percentageRevenueOnCredit) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.revenueWebsiteCash') }} </span>
              </el-col>
              <el-col :span="6">
                <span>{{ formatAmount(loadedData.revenuePerCategory.revenueWebsiteCash) }}</span>
              </el-col>
              <el-col :span="3">
                <span>{{ formatPercentage(loadedData.revenuePerCategory.percentageRevenueWebsiteCash) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.revenueWebsiteElectronic') }} </span>
              </el-col>
              <el-col :span="6">
                <span>{{ formatAmount(loadedData.revenuePerCategory.revenueWebsiteElectronic) }}</span>
              </el-col>
              <el-col :span="3">
                <span>{{ formatPercentage(loadedData.revenuePerCategory.percentageRevenueWebsiteElectronic) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.revenueWebsitePIN') }} </span>
              </el-col>
              <el-col :span="6">
                <span>{{ formatAmount(loadedData.revenuePerCategory.revenueWebsitePIN) }}</span>
              </el-col>
              <el-col :span="3">
                <span>{{ formatPercentage(loadedData.revenuePerCategory.percentageRevenueWebsitePIN) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.revenueThuisbezorgdCash') }} </span>
              </el-col>
              <el-col :span="6">
                <span>{{ formatAmount(loadedData.revenuePerCategory.revenueThuisbezorgdCash) }}</span>
              </el-col>
              <el-col :span="3">
                <span>{{ formatPercentage(loadedData.revenuePerCategory.percentageRevenueThuisbezorgdCash) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.revenueThuisbezorgdElectronic') }} </span>
              </el-col>
              <el-col :span="6">
                <span>{{ formatAmount(loadedData.revenuePerCategory.revenueThuisbezorgdElectronic) }}</span>
              </el-col>
              <el-col :span="3">
                <span>{{
                  formatPercentage(loadedData.revenuePerCategory.percentageRevenueThuisbezorgdElectronic)
                }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.revenueThuisbezorgdPIN') }} </span>
              </el-col>
              <el-col :span="6">
                <span>{{ formatAmount(loadedData.revenuePerCategory.revenueThuisbezorgdPIN) }}</span>
              </el-col>
              <el-col :span="3">
                <span>{{ formatPercentage(loadedData.revenuePerCategory.percentageRevenueThuisbezorgdPIN) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.revenueSneletenCash') }} </span>
              </el-col>
              <el-col :span="6">
                <span>{{ formatAmount(loadedData.revenuePerCategory.revenueSneletenCash) }}</span>
              </el-col>
              <el-col :span="3">
                <span>{{ formatPercentage(loadedData.revenuePerCategory.percentageRevenueSneletenCash) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.revenueSneletenElectronic') }} </span>
              </el-col>
              <el-col :span="6">
                <span>{{ formatAmount(loadedData.revenuePerCategory.revenueSneletenElectronic) }}</span>
              </el-col>
              <el-col :span="3">
                <span>{{ formatPercentage(loadedData.revenuePerCategory.percentageRevenueSneletenElectronic) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.revenueSneletenPIN') }} </span>
              </el-col>
              <el-col :span="6">
                <span>{{ formatAmount(loadedData.revenuePerCategory.revenueSneletenPIN) }}</span>
              </el-col>
              <el-col :span="3">
                <span>{{ formatPercentage(loadedData.revenuePerCategory.percentageRevenueSneletenPIN) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.revenueAppsTotal') }} </span>
              </el-col>
              <el-col :span="6">
                <span>{{ formatAmount(loadedData.revenuePerCategory.revenueAppsTotal) }}</span>
              </el-col>
              <el-col :span="3">
                <span>{{ formatPercentage(loadedData.revenuePerCategory.percentageRevenueAppsTotal) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.revenuePayter') }} </span>
              </el-col>
              <el-col :span="6">
                <span>{{ formatAmount(loadedData.revenuePerCategory.revenuePayter) }}</span>
              </el-col>
              <el-col :span="3">
                <span>{{ formatPercentage(loadedData.revenuePerCategory.percentageRevenuePayter) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.revenueGiftCard') }} </span>
              </el-col>
              <el-col :span="6">
                <span>{{ formatAmount(loadedData.revenuePerCategory.revenueGiftCard) }}</span>
              </el-col>
              <el-col :span="3">
                <span>{{ formatPercentage(loadedData.revenuePerCategory.percentageRevenueGiftCard) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="15">
                <span> {{ $t('revenueReport.revenueOthers') }} </span>
              </el-col>
              <el-col :span="6">
                <span>{{ formatAmount(loadedData.revenuePerCategory.revenueOthers) }}</span>
              </el-col>
              <el-col :span="3">
                <span>{{ formatPercentage(loadedData.revenuePerCategory.percentageRevenueOthers) }}</span>
              </el-col>
            </el-row>
          </div>
        </el-col>

        <el-col :xs="24" :lg="8">
          <div class="grid-content report-block">
            <el-row>
              <el-col :span="10">
                <span
                  ><strong>{{ $t('revenueReport.vat') }}</strong>
                </span>
              </el-col>
              <el-col :span="7">
                <span
                  ><strong>{{ $t('revenueReport.amount') }}</strong>
                </span>
              </el-col>
              <el-col :span="7">
                <span
                  ><strong>{{ $t('revenueReport.revenue') }}</strong>
                </span>
              </el-col>
            </el-row>
            <el-row v-for="vatTotal in loadedData.revenueTotals.vatTotals" :key="vatTotal.percentageVAT">
              <el-col :span="10">
                <span> {{ formatPercentage(vatTotal.percentageVAT / 100) }}</span>
              </el-col>
              <el-col :span="7">
                <span>{{ formatAmount(vatTotal.amountVAT) }}</span>
              </el-col>
              <el-col :span="7">
                <span>{{ formatAmount(vatTotal.totalRevenue) }}</span>
              </el-col>
            </el-row>
          </div>
        </el-col>

        <el-col :xs="24" :lg="8">
          <div class="grid-content report-block">
            <el-row>
              <el-col :span="17">
                <span> {{ $t('revenueReport.totalRevenueIncludingVAT') }} </span>
              </el-col>
              <el-col :span="7">
                <span>{{ formatAmount(loadedData.revenueTotals.totalRevenueIncludingVAT) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="17">
                <span> {{ $t('revenueReport.totalRevenueExcludingVAT') }} </span>
              </el-col>
              <el-col :span="7">
                <span>{{ formatAmount(loadedData.revenueTotals.totalRevenueExcludingVAT) }}</span>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :xs="24" :lg="8">
          <div class="grid-content report-block">
            <el-row>
              <el-col :span="17">
                <span> {{ $t('revenueReport.totalRevenueCash') }} </span>
              </el-col>
              <el-col :span="7">
                <span>{{ formatAmount(loadedData.revenueTotals.totalRevenueCash) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="17">
                <span> {{ $t('revenueReport.totalRevenueElectronic') }} </span>
              </el-col>
              <el-col :span="7">
                <span>{{ formatAmount(loadedData.revenueTotals.totalRevenueElectronic) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="17">
                <span> {{ $t('revenueReport.totalRevenuePIN') }} </span>
              </el-col>
              <el-col :span="7">
                <span>{{ formatAmount(loadedData.revenueTotals.totalRevenuePIN) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="17">
                <span> {{ $t('revenueReport.totalRevenueOnCredit') }} </span>
              </el-col>
              <el-col :span="7">
                <span>{{ formatAmount(loadedData.revenueTotals.totalRevenueOnCredit) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="17">
                <span> {{ $t('revenueReport.totalRevenueGiftCard') }} </span>
              </el-col>
              <el-col :span="7">
                <span>{{ formatAmount(loadedData.revenueTotals.totalRevenueGiftCard) }}</span>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :xs="24" :lg="8">
          <div class="grid-content report-block">
            <el-row>
              <el-col :span="17">
                <span> {{ $t('revenueReport.totalDeliveryCosts') }} </span>
              </el-col>
              <el-col :span="7">
                <span>{{ formatAmount(loadedData.revenueTotals.totalDeliveryCosts) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="17">
                <span> {{ $t('revenueReport.totalDeposit') }} </span>
              </el-col>
              <el-col :span="7">
                <span>{{ formatAmount(loadedData.revenueTotals.totalDeposit) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="17">
                <span> {{ $t('revenueReport.totalSupCharges') }} </span>
              </el-col>
              <el-col :span="7">
                <span>{{ formatAmount(loadedData.revenueTotals.totalSupCharges) }}</span>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :xs="24" :lg="8">
          <div class="grid-content report-block">
            <el-row>
              <el-col :span="17">
                <span> {{ $t('revenueReport.totalPayed') }} </span>
              </el-col>
              <el-col :span="7">
                <span>{{ formatAmount(loadedData.revenueTotals.totalPayed) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="17">
                <span> {{ $t('revenueReport.totalRevenueNotSettled') }} </span>
              </el-col>
              <el-col :span="7">
                <span>{{ formatAmount(loadedData.revenueTotals.totalRevenueNotSettled) }}</span>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="17">
                <span> {{ $t('revenueReport.totalCredited') }} </span>
              </el-col>
              <el-col :span="7">
                <span>{{ formatAmount(loadedData.revenueTotals.totalCredited) }}</span>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :xs="24" :lg="8">
          <div class="grid-content report-block">
            <el-row>
              <el-col :span="17">
                <span> {{ $t('revenueReport.customerAverageRevenue') }} </span>
              </el-col>
              <el-col :span="7">
                <span>{{ formatAmount(loadedData.customerAverageRevenue) }}</span>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import EditorButton from '@/components/crud/EditorButton';
import { toString } from '@/utils/date';
import { Loading } from 'element-ui';
import { toDateStringVisual } from '../../utils/date';
import { ForceFileDownload, getFileNameFromResponseHeaders } from '@/utils/fileDownload';

export default {
  name: 'EmployeeWorklog',
  components: {
    EditorButton
  },
  data() {
    return {
      loading: false,
      loadedData: {
        revenueCounts: {},
        revenuePerCategory: {},
        revenueTotals: {},
        employeeCosts: {}
      },
      restaurantID: null,
      fromDate: new Date(),
      untilDate: new Date()
    };
  },
  computed: {
    listParams() {
      return {
        restaurantID: this.restaurantID,
        fromDate: toString(this.fromDate, 'YYYY-MM-DD'),
        untilDate: toString(this.untilDate, 'YYYY-MM-DD')
      };
    },
    restaurants() {
      return this.$store.getters['restaurant/loadedItems'].filter(e => e.onlineYN);
    },
    selectedRestaurantName() {
      const restaurant = this.$store.getters['restaurant/getLoadedItemByID'](
        this.restaurantID || this.$store.getters.restaurantId
      );
      return restaurant && restaurant.name;
    },
    pickerOptions() {
      return {
        disabledDate(givenDate) {
          const lastDate = new Date();
          const date = new Date(givenDate);
          return date > lastDate;
        },
        firstDayOfWeek: 1
      };
    }
  },
  created() {
    this.$store.dispatch('restaurant/getItems');

    this.refreshList();
  },
  methods: {
    refreshList() {
      if (!this.restaurantID && !this.$store.getters.restaurantId) {
        return;
      }
      this.loadAsync();
    },
    async loadAsync() {
      return new Promise(() => {
        const loadingInstance = Loading.service({
          lock: true,
          background: 'rgba(255, 255, 255, 0.5)'
        });
        this.$store
          .dispatch('restaurant/getRevenueReport', this.listParams)
          .then(data => {
            this.loadedData = data;
          })
          .finally(() => {
            loadingInstance.close();
          });
      });
    },
    async downloadReportAsync() {
      return new Promise(() => {
        const loadingInstance = Loading.service({
          lock: true,
          background: 'rgba(255, 255, 255, 0.5)'
        });
        const reportProperties = {
          name: 'Revenue report',
          entity: 'RevenueReport',
          downloadFileFormat: 'excel',
          columns: this.createColumns(this.loadedData)
        };

        this.$store
          .dispatch('restaurant/downloadRevenueReport', { params: this.listParams, data: reportProperties })
          .then(response => {
            const fileName = getFileNameFromResponseHeaders(response.headers);
            ForceFileDownload(response.data, fileName);
          })
          .finally(() => {
            loadingInstance.close();
          });
        loadingInstance.close();
      });
    },
    createColumns(data) {
      const columns = [];

      this.createColumnsFromData(data).forEach(c => columns.push(c));
      columns.push(this.createColumnManually('header'));
      columns.push(this.createColumnManually('vat'));
      columns.push(this.createColumnManually('amount'));
      columns.push(this.createColumnManually('revenue'));

      return columns;
    },
    createColumnManually(fieldName) {
      return { field: fieldName, label: this.$i18n.t('revenueReport.' + fieldName) };
    },
    createColumnsFromData(data) {
      const columns = [];
      const propertyNames = Object.keys(data);
      const values = Object.values(data);

      for (let i = 0; i < propertyNames.length; i++) {
        const key = 'revenueReport.' + propertyNames[i];
        if (typeof values[i] === 'object') {
          this.createColumnsFromData(values[i]).forEach(c => columns.push(c));
        } else if (this.$i18n.te(key)) {
          columns.push({ field: propertyNames[i], label: this.$i18n.t(key) });
        }
      }

      return columns;
    },
    handleFilterChanged() {
      this.refreshList();
    },
    getMaxTableHeight() {
      if (this.$refs.reportContainer) {
        return window.innerHeight - this.$refs.reportContainer.getBoundingClientRect().top - 100;
      }
    },
    formatPercentage(value) {
      const val = value ? value * 100 : 0;
      return val.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 }) + '%';
    },
    formatPercentage2(value) {
      const val = value ?? 0;
      return val.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%';
    },
    formatAmount(value) {
      return '€ ' + (value ?? 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    },
    formatHours(value) {
      return (value ?? 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    },
    formatDate(date) {
      return toDateStringVisual(date);
    }
  }
};
</script>

<style>
.el-row {
  padding: 7px;
}
.revenue-report-header {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 7px;
}
.bg-white {
  background: white;
}
.grid-content {
  margin-top: 20px;
}
</style>
